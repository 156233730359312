<template lang="">
  <div v-if="ticketData.status !== 'CANCEL'">
    <div
      v-if="config.showPrice && fareBookingDataArray.length"
      class="d-flex flex-column"
      :class="`${isBasicType ? 'border-theme border-2 rounded-lg' : 'gap-8px'}`"
    >
      <div
        v-if="config.showPriceDetail"
        class="d-flex-center flex-column gap-8px"
      >
        <div
          v-if="!isBasicType"
          class="font-weight-bolder color-theme px-24px d-flex justify-content-start w-100 text-18px"
          contenteditable="true"
        >
          {{ $t('ticket.ticketPrice', config.locale) }}
        </div>

        <!-- SECTION GIÁ TỪNG PAX -->
        <div
          v-for="(paxFareItem, paxFareIndex) in fareBookingDataArray"
          :key="paxFareIndex"
          class="w-100"
        >
          <div
            v-if="paxFareItem && paxFareItem.trip"
            class="text-dark font-weight-bolder d-flex-between text-18px"
            :class="`${isBasicType ? 'bg-theme-dark px-1 py-50' : 'mb-50'}`"
          >
            <div
              v-if="isBasicType"
              :class="`font-weight-bolder ${!isBasicType ? 'color-theme' : 'text-white'}`"
              contenteditable="true"
            >
              {{ $t('ticket.ticketPrice', config.locale) }}
            </div>
            <span
              :class="`${isBasicType ? 'text-white' : ''}`"
              contenteditable
            >
              {{ paxFareItem.trip }}
            </span>
          </div>

          <div
            class="section-table"
            :class="[{ 'border-theme': !config.showBackgroundColor}, { 'border-0': isBasicType }]"
          >
            <b-table
              v-if="paxFareItem && paxFareItem.paxFare && paxFareItem.paxFare.length"
              centered
              responsive
              :borderless="!isBasicType || true"
              :class="`mb-0 ${isBasicType ? 'table-border-theme' : ''}`"
              thead-class="text-dark align-middle text-nowrap text-right border-0"
              tbody-class="text-dark align-middle text-nowrap text-right border-0"
              :fields="tableColumnsPaxFareInfoTicket"
              :items="paxFareItem.paxFare"
            >
              <template
                v-for="(column, indexColumn) in tableColumnsPaxFareInfoTicket"
                #[`head(${column.key})`]
              >
                <span
                  :key="indexColumn"
                  contenteditable
                  class="fw-600 text-14px"
                >
                  {{ $t(`reservation.sellingPriceDetails.tableColumnsPaxFareInfoTicket.${column.key}`, config.locale) }}
                </span>
              </template>
              <template #cell(passenger)="data">
                <div class="text-right font-weight-bolder">
                  <span contenteditable>{{ $t(`reservation.${data.item.paxType}`, config.locale) }} x{{ data.item.amountPassenger }}</span>
                </div>
              </template>
              <template #cell(netPrice)="data">
                <div class="text-right font-weight-bolder">
                  <IAmInputMoney
                    :value-money="Math.floor( data.item.netPrice / data.item.amountPassenger)"
                    placeholder=""
                    :disable-class="true"
                    :can-input-negative="true"
                    :on-blur="(text) => handleChangePax(text, paxFareIndex, data.item.paxType, 'netPrice')"
                  />
                </div>
              </template>
              <template #cell(tax)="data">
                <div class="text-right font-weight-bolder">
                  <IAmInputMoney
                    :value-money="Math.floor(data.item.tax / data.item.amountPassenger)"
                    placeholder=""
                    :disable-class="true"
                    :can-input-negative="true"
                    :on-blur="(text) => handleChangePax(text, paxFareIndex, data.item.paxType, 'tax')"
                  />
                </div>
              </template>
              <template #cell(total)="data">
                <div class="text-right font-weight-bolder color-theme">
                  <IAmInputMoney
                    customClass="fw-600"
                    :value-money="Math.floor(data.item.total / data.item.amountPassenger)"
                    placeholder=""
                    :disable-class="true"
                    :can-input-negative="true"
                    :on-blur="(text) => handleChangePax(text, paxFareIndex, data.item.paxType, 'total')"
                  />
                </div>
              </template>
            </b-table>

            <b-table
              v-else-if="paxFareItem && paxFareItem.fareInfoTicket"
              centered
              responsive
              :borderless="!isBasicType || true"
              :class="`mb-0 ${isBasicType ? 'table-border-theme' : ''}`"
              thead-class="text-dark align-middle text-nowrap text-right border-0"
              tbody-class="text-dark align-middle text-nowrap text-right border-0"
              :fields="tableColumnsFareInfoTicket"
              :items="paxFareItem.fareInfoTicket"
            >
              <template
                v-for="(column, indexColumn) in tableColumnsFareInfoTicket"
                #[`head(${column.key})`]
              >
                <span
                  :key="indexColumn"
                  contenteditable
                  class="fw-600 text-14px"
                >
                  {{ $t(`reservation.sellingPriceDetails.tableColumnsPaxFareInfoTicket.${column.key}`, config.locale) }}
                </span>
              </template>
              <template #cell(passenger)="data">
                <div class="text-right font-weight-bolder">
                  <span contenteditable>{{ data.item.passenger }}</span>
                </div>
              </template>
              <template #cell(netPrice)="data">
                <div class="text-right font-weight-bolder">
                  <IAmInputMoney
                    :value-money="data.item.netPrice"
                    placeholder=""
                    :disable-class="true"
                    :can-input-negative="true"
                    :on-blur="(text) => handleChangePax(text, paxFareIndex, data.item.passenger, 'netPrice', true)"
                  />
                </div>
              </template>
              <template #cell(tax)="data">
                <div class="text-right font-weight-bolder">
                  <IAmInputMoney
                    :value-money="data.item.tax"
                    placeholder=""
                    :disable-class="true"
                    :can-input-negative="true"
                    :on-blur="(text) => handleChangePax(text, paxFareIndex, data.item.passenger, 'tax', true)"
                  />
                </div>
              </template>
              <template #cell(total)="data">
                <div class="text-right font-weight-bolder color-theme">
                  <IAmInputMoney
                    :value-money="data.item.total"
                    placeholder=""
                    customClass="fw-600"
                    :disable-class="true"
                    :can-input-negative="true"
                    :on-blur="(text) => handleChangePax(text, paxFareIndex, data.item.passenger, 'total', true)"
                  />
                </div>
              </template>
            </b-table>

            <!-- Tổng 1 bảng -->
            <div
              v-if="showTotalPerTrip"
              class="text-right px-1 py-50"
              :class="`${isBasicType ? 'border-theme-top' : ''}`"
            >
              <span contenteditable>
                {{ $t('ticket.priceTable.total', config.locale) }}:
              </span>
              <IAmInputMoney
                :value-money="paxFareItem.total"
                placeholder=""
                custom-class="fw-700 color-theme"
                :disable-class="true"
                :can-input-negative="true"
                :on-blur="(text) => handleChangeBookingTotal(text, paxFareIndex)"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="config.showAncillaries && config.showPriceDetail && ancillaryTotal"
        :class="`d-flex-center flex-column mt-1 ${!isBasicType ? 'gap-8px' : ''}`"
      >
        <div
          class="font-weight-bolder px-24px d-flex justify-content-start w-100 text-18px"
          :class="isBasicType ? 'text-white bg-theme-dark px-1 py-50' : 'color-theme'"
          contenteditable="true"
        >
          {{ $t('ticket.ticketAncillaries', config.locale) }}
        </div>

        <!-- SECTION DỊCH VỤ BỔ SUNG -->
        <div
          v-for="(paxFareItem, paxFareIndex) in ancillariesBookingDataArray"
          :key="paxFareIndex"
          class="w-100"
        >
          <div
            v-if="paxFareItem && paxFareItem.trip"
            class="text-dark font-weight-bolder mb-50"
          >
            <span class="text-16px">
              <span contenteditable>
                {{ paxFareItem.trip }}
              </span>
            </span>
          </div>

          <div
            class="section-table"
            :class="[
              { 'border-theme': !config.showBackgroundColor && !isBasicType},
            ]"
          >
            <AncillariesTable
              :isBasicType="isBasicType"
              :reservations-data="paxFareItem"
              :on-change="(text, itemId) => handleChangeAncillaryItem(text, paxFareIndex, itemId)"
              :config="config"
            />

            <!-- Tổng 1 bảng -->
            <div
              v-if="showTotalPerTrip"
              class="text-right m-50"
            >
              <span contenteditable>
                {{ $t('ticket.priceTable.total', config.locale) }}:
              </span>
              <IAmInputMoney
                :value-money="paxFareItem.ancillaryTotal"
                placeholder=""
                custom-class="fw-700 color-theme"
                :disable-class="true"
                :can-input-negative="true"
                :on-blur="(text) => handleChangeAncillarySum(text, paxFareIndex)"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        class="d-flex flex-column gap-2 px-1 py-75 w-100"
        :class="isBasicType ? 'bg-theme-dark text-white border-theme-top' : 'rounded-8px bg-theme'"
        :style="`${config.showBackgroundColor && !isBasicType ? `background-color: ${currentBgColor} !important` : ''}`"
      >
        <!-- ANCHOR Dịch vụ bổ sung -->
        <div
          v-if="totalAncillaryPriceRaw && config.showPriceDetail && config.showAncillaries"
          class="d-flex align-items-end justify-content-end"
        >
          <span
            contenteditable
            class="font-weight-bolder"
          >
            {{ $t('ticket.ticketAncillaries', config.locale) }}:
          </span>
          <IAmInputMoney
            :value-money="totalAncillaryPrice"
            placeholder=""
            :custom-class="`text-18px fw-700 ${isBasicType ? 'text-white' : 'color-theme'}`"
            :disable-class="true"
            :can-input-negative="true"
            :on-blur="(text) => handleChangeAncillaryPrice(text)"
          />
        </div>
        <!-- ANCHOR Tổng cuối cùng -->
        <div class="d-flex align-items-end justify-content-end">
          <span
            contenteditable
            class="font-weight-bolder"
          >
            {{ $t('ticket.priceTable.totalOfPrice', config.locale) }}:
          </span>
          <IAmInputMoney
            :value-money="sumAll"
            placeholder=""
            :custom-class="`text-22px fw-700 ${isBasicType ? 'text-white' : 'color-theme'}`"
            :disable-class="true"
            :can-input-negative="true"
            :on-blur="(text) => handleChangeSumAll(text)"
          />
        </div>
      </div>
      <!-- !SECTION -->
    </div>
  </div>
</template>
<script>
import { BTable } from 'bootstrap-vue'
import { computed, toRefs } from '@vue/composition-api'

import IAmInputMoney from '@/components/IAmInputMoney.vue'

import AncillariesTable from './AncillariesTable.vue'

export default {
  components: {
    IAmInputMoney,
    BTable,
    AncillariesTable,
  },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    ticketData: {
      type: Object,
      default: () => ({}),
    },
    currentBgColor: {
      type: [String, null],
      default: null,
    },
    eticketTemplate: {
      type: String,
      default: 'modern',
    },
    fareBookingDataArray: {
      type: Array,
      default: () => [],
    },
    ancillariesBookingDataArray: {
      type: Array,
      default: () => [],
    },
    sumAll: {
      type: Number,
      default: 0,
    },
    totalAncillaryPriceRaw: {
      type: Number,
      default: 0,
    },
    ancillaryTotal: {
      type: Number,
      default: 0,
    },
    showTotalPerTrip: {
      type: Boolean,
      default: false,
    },
    totalAncillaryPrice: {
      type: Number,
      default: 0,
    },
    handleChangePax: {
      type: Function,
      default: () => {},
    },
    handleChangeAncillaryPrice: {
      type: Function,
      default: () => {},
    },
    handleChangeSumAll: {
      type: Function,
      default: () => {},
    },
    handleChangeAncillaryItem: {
      type: Function,
      default: () => {},
    },
    handleChangeBookingTotal: {
      type: Function,
      default: () => {},
    },
    handleChangeAncillarySum: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const configTicket = toRefs(props).config
    const currentBgColor = toRefs(props).currentBgColor
    const ticketData = toRefs(props).ticketData

    const tableColumnsPaxFareInfoTicket = computed(() => {
      const arr = ['passenger', 'netPrice', 'tax', 'total']
      return (configTicket.value?.showPriceNetFare ? arr : arr.filter(item => ['passenger', 'total'].includes(item)))
        .map(item => ({
          key: item,
          label: item,
          thStyle: `background-color: ${configTicket.value.showBackgroundColor ? currentBgColor.value : 'white'} !important`,
        }))
    })

    const tableColumnsFareInfoTicket = computed(() => {
      const arr = ticketData.value.source === 'F1' ? ['passenger', 'total'] : ['passenger', 'netPrice', 'tax', 'total']
      return (configTicket.value?.showPriceNetFare ? arr : arr.filter(item => ['total'].includes(item))).map(item => ({
        key: item,
        label: item,
        thStyle: `background-color: ${configTicket.value.showBackgroundColor ? currentBgColor.value : 'white'} !important`,
      }))
    })

    const isBasicType = computed(() => props.eticketTemplate === 'basic')

    return {
      tableColumnsPaxFareInfoTicket,
      tableColumnsFareInfoTicket,
      isBasicType,
    }
  },
}
</script>
<style lang="">

</style>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.flights.length ? _c('div', {
    staticClass: "d-flex flex-column gap-16px"
  }, _vm._l(_vm.flights, function (flight, index) {
    return _c('div', {
      key: index,
      staticClass: "section-flight rounded-8px d-flex flex-column position-relative border-theme border-2",
      style: _vm.config.showBackgroundColor ? "background-color: ".concat(_vm.currentBgColor, " !important") : ''
    }, [_c('div', {
      staticClass: "d-flex gap-4px position-absolute",
      staticStyle: {
        "top": "8px",
        "right": "8px",
        "z-index": "10"
      }
    }, [_c('b-button', {
      staticClass: "pax-button",
      staticStyle: {
        "padding": "4px",
        "border": "0.5px solid white !important"
      },
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removeFlight(index);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "MinusIcon",
        "size": "16"
      }
    })], 1), _c('b-button', {
      staticClass: "pax-button",
      staticStyle: {
        "padding": "4px",
        "border": "0.5px solid white !important"
      },
      attrs: {
        "variant": "success"
      },
      on: {
        "click": function click($event) {
          return _vm.addFlight(index, flight);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "PlusIcon",
        "size": "16"
      }
    })], 1)], 1), _c('div', {
      staticClass: "d-flex-center bg-theme-dark text-white"
    }, [_c('div', {
      staticClass: "mx-1 my-50 bg-white py-25 px-50 rounded-8px"
    }, [_c('IAmLogoAirline', {
      attrs: {
        "airline": flight.segments[0].operating === 'BL' ? flight.segments[0].operating : flight.segments[0].airline,
        "long-logo": true,
        "hide-tooltip": true,
        "height": 48,
        "blob": ""
      }
    })], 1), _c('div', {
      staticClass: "flex-1 d-flex-center fw-700 text-24px"
    }, [_vm._v(" " + _vm._s(_vm.resolveTitleFlight(flight)) + " ")])]), _vm._l(flight.segments, function (segment, sIndex) {
      var _segment$segmentNotMo, _segment$segmentNotMo2, _segment$segmentNotMo3, _segment$segmentNotMo4, _segment$segmentNotMo5, _segment$segmentNotMo6, _segment$segmentNotMo7, _segment$segmentNotMo8;
      return [_c('div', {
        key: "segment-wrapper-".concat(index, "-").concat(sIndex),
        staticClass: "segment-content"
      }, [_vm.agencyAvatar ? _c('div', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: _vm.config.showBackground,
          expression: "config.showBackground"
        }],
        style: "position: absolute; inset: 30px; opacity: 0.08; background: url(".concat(_vm.agencyAvatar, ") no-repeat center / contain")
      }) : _vm._e(), _c('div', {
        staticClass: "segment-content-l flex-1 d-flex gap-16px text-black",
        staticStyle: {
          "padding-right": "0"
        }
      }, [_c('div', {
        staticClass: "flex-1 d-flex"
      }, [_c('div', {
        staticClass: "d-flex flex-column gap-12px fw-800 text-22px w-100"
      }, [_c('div', [_c('div', {
        ref: "fromRef",
        refInFor: true,
        attrs: {
          "contenteditable": "true"
        },
        on: {
          "focus": _vm.onFocus,
          "blur": _vm.onBlur
        }
      }, [_vm._v(" " + _vm._s(_vm.getAirportName(segment.from)) + " ")]), _c('div', {
        staticClass: "border-bottom-dashed-theme"
      })]), _c('div', {
        staticClass: "d-flex gap-4px align-items-center"
      }, [_c('span', {
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.departTime', _vm.config.locale)) + ": ")]), _c('span', {
        staticClass: "text-24px color-theme rounded-8px",
        staticStyle: {
          "padding-top": "2px"
        },
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(segment.departTime) + " ")])]), _c('div', {
        staticClass: "text-20px"
      }, [_c('span', {
        staticClass: "text-uppercase",
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(segment === null || segment === void 0 ? void 0 : (_segment$segmentNotMo = segment.segmentNotModified) === null || _segment$segmentNotMo === void 0 ? void 0 : (_segment$segmentNotMo2 = _segment$segmentNotMo.departure) === null || _segment$segmentNotMo2 === void 0 ? void 0 : _segment$segmentNotMo2.at, segment === null || segment === void 0 ? void 0 : (_segment$segmentNotMo3 = segment.segmentNotModified) === null || _segment$segmentNotMo3 === void 0 ? void 0 : (_segment$segmentNotMo4 = _segment$segmentNotMo3.departure) === null || _segment$segmentNotMo4 === void 0 ? void 0 : _segment$segmentNotMo4.timeZone, _vm.config.locale).dayOfWeek || segment.departWeekday) + ", " + _vm._s(segment.departDate) + " ")])]), segment.startTerminal ? _c('div', {
        staticClass: "d-flex gap-4px align-items-center"
      }, [_c('IAmIcon', {
        attrs: {
          "icon": "location",
          "size": "18"
        }
      }), _c('span', {
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.terminal', _vm.config.locale)) + " " + _vm._s(segment.startTerminal.replace('TERMINAL', '')) + " ")])], 1) : _vm._e(), _vm.config.showBookingClass ? _c('div', {
        key: _vm.bookingClassKey,
        staticClass: "d-flex-center cursor-pointer position-relative",
        on: {
          "click": function click($event) {
            return _vm.onClickBookingClass(segment);
          }
        }
      }, [segment !== null && segment !== void 0 && segment.customBookingClass || _vm.getGroupClass(segment) ? _c('img', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover.top.window",
          value: _vm.$t('ticket.clickChangeBookingClassBadge'),
          expression: "$t('ticket.clickChangeBookingClassBadge')",
          modifiers: {
            "hover": true,
            "top": true,
            "window": true
          }
        }],
        staticClass: "rounded-8px w-100",
        staticStyle: {
          "max-width": "320px"
        },
        attrs: {
          "src": require("@/assets/images/ticket/img_ticket_".concat(((segment === null || segment === void 0 ? void 0 : segment.customBookingClass) || _vm.getGroupClass(segment)).toLowerCase(), "_class.png"))
        }
      }) : _c('img', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover.v-danger.top.window",
          value: _vm.$t('ticket.errorBookingClassBadge'),
          expression: "$t('ticket.errorBookingClassBadge')",
          modifiers: {
            "hover": true,
            "v-danger": true,
            "top": true,
            "window": true
          }
        }],
        staticClass: "rounded-8px w-100 border-danger",
        staticStyle: {
          "max-width": "320px",
          "border-width": "2px !important"
        },
        attrs: {
          "src": require("@/assets/images/ticket/img_ticket_".concat('economy', "_class.png"))
        }
      })]) : _vm._e()]), _c('div', {
        staticClass: "divide-line ml-1"
      })]), _c('div', {
        staticClass: "flex-1 d-flex"
      }, [_c('div', {
        staticClass: "d-flex flex-column gap-12px fw-600 text-22px w-100"
      }, [_c('div', [_c('div', {
        ref: "toRef",
        refInFor: true,
        attrs: {
          "contenteditable": "true"
        },
        on: {
          "focus": _vm.onFocus,
          "blur": _vm.onBlur
        }
      }, [_vm._v(" " + _vm._s(_vm.getAirportName(segment.to)) + " ")]), _c('div', {
        staticClass: "border-bottom-dashed-theme"
      })]), _c('div', {
        staticClass: "d-flex gap-4px align-items-center"
      }, [_c('span', {
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.arrivalTime', _vm.config.locale)) + ": ")]), _c('span', {
        staticClass: "text-24px color-theme rounded-8px",
        staticStyle: {
          "padding-top": "2px"
        },
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(segment.arrivalTime) + " ")])]), _c('div', {
        staticClass: "text-20px"
      }, [_c('span', {
        staticClass: "text-uppercase",
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(segment === null || segment === void 0 ? void 0 : (_segment$segmentNotMo5 = segment.segmentNotModified) === null || _segment$segmentNotMo5 === void 0 ? void 0 : (_segment$segmentNotMo6 = _segment$segmentNotMo5.arrival) === null || _segment$segmentNotMo6 === void 0 ? void 0 : _segment$segmentNotMo6.at, segment === null || segment === void 0 ? void 0 : (_segment$segmentNotMo7 = segment.segmentNotModified) === null || _segment$segmentNotMo7 === void 0 ? void 0 : (_segment$segmentNotMo8 = _segment$segmentNotMo7.arrival) === null || _segment$segmentNotMo8 === void 0 ? void 0 : _segment$segmentNotMo8.timeZone, _vm.config.locale).dayOfWeek || segment.arrivalWeekday) + ", " + _vm._s(segment.arrivalDate) + " ")])]), segment.endTerminal ? _c('div', {
        staticClass: "d-flex gap-4px align-items-center"
      }, [_c('IAmIcon', {
        attrs: {
          "icon": "location",
          "size": "18"
        }
      }), _c('span', {
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.terminal', _vm.config.locale)) + " " + _vm._s(segment.endTerminal.replace('TERMINAL', '')) + " ")])], 1) : _vm._e()]), _c('div', {
        staticClass: "divide-line ml-1"
      })])]), _c('div', {
        staticClass: "segment-content-r d-flex flex-column gap-8px fw-600 text-20px",
        staticStyle: {
          "max-width": "unset"
        }
      }, [_c('div', [_c('div', {
        staticClass: "airline d-flex-between gap-4px"
      }, [_c('div', {
        staticClass: "text-nowrap"
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.airline', _vm.config.locale)) + " ")]), _vm.airlinesLogoLong.includes(segment.operating || segment.airline) ? _c('div', [_c('IAmLogoAirline', {
        attrs: {
          "airline": segment.operating === 'BL' ? segment.operating : segment.airline,
          "long-logo": true,
          "hide-tooltip": true,
          "height": "60",
          "blob": ""
        }
      })], 1) : _c('div', {
        staticClass: "d-flex-center gap-1"
      }, [_c('IAmLogoAirline', {
        attrs: {
          "airline": segment.operating === 'BL' ? segment.operating : segment.airline,
          "hide-tooltip": true,
          "height": 50,
          "blob": ""
        }
      }), _c('div', {
        staticClass: "text-nowrap"
      }, [_vm._v(" " + _vm._s(_vm.getAirlineNameByCode(segment.operating === 'BL' ? segment.operating : segment.airline)) + " ")])], 1)]), _c('div', {
        staticClass: "border-bottom-dashed-theme"
      })]), _c('div', {
        staticClass: "d-flex align-items-center gap-4px"
      }, [_c('div', {
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.flight', _vm.config.locale)) + ": ")]), _c('div', {
        staticClass: "color-theme px-50",
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(segment.flight) + " ")])]), segment.airCraft ? _c('div', {
        staticClass: "d-flex align-items-center gap-4px"
      }, [_c('div', {
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.aircraft', _vm.config.locale)) + ": ")]), _c('div', {
        staticClass: "px-50",
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.getAirCraftByIata(segment.airCraft)) + " ")])]) : _vm._e(), _c('div', {
        staticClass: "d-flex align-items-center gap-4px"
      }, [_c('div', {
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.duration', _vm.config.locale)) + ": ")]), _c('div', {
        staticClass: "px-50",
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(segment.durationHM) + " ")])]), (segment.segmentNotModified ? _vm.getBaggageInBookingDetail(segment.segmentNotModified, _vm.ticketData, _vm.config.locale).carryBag : _vm.getHandBaggage(segment)) ? _c('div', {
        staticClass: "d-flex align-items-center gap-4px"
      }, [_c('div', {
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.handBaggage', _vm.config.locale)) + ": ")]), _c('div', {
        staticClass: "px-50",
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(segment.segmentNotModified ? _vm.getBaggageInBookingDetail(segment.segmentNotModified, _vm.ticketData, _vm.config.locale).carryBag : _vm.getHandBaggage(segment)) + " ")])]) : _vm._e(), (segment.segmentNotModified ? _vm.getBaggageInBookingDetail(segment.segmentNotModified, _vm.ticketData, _vm.config.locale).checkinBag : _vm.getFreeBaggage(segment)) ? _c('div', {
        staticClass: "d-flex align-items-center gap-4px"
      }, [_c('div', {
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.freeBaggage', _vm.config.locale)) + ": ")]), _c('div', {
        staticClass: "px-50",
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(segment.segmentNotModified ? _vm.getBaggageInBookingDetail(segment.segmentNotModified, _vm.ticketData, _vm.config.locale).checkinBag : _vm.getFreeBaggage(segment)) + " ")])]) : _vm._e()])]), segment.transitTime ? _c('div', {
        key: "transit-time".concat(index, "-").concat(sIndex),
        staticClass: "d-flex-center gap-12px px-2 color-theme my-12px"
      }, [_c('div', {
        staticClass: "border-dot flex-grow-1"
      }), _c('div', {
        staticClass: "flex-shrink-0 font-weight-bolder text-14px",
        attrs: {
          "contenteditable": ""
        }
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.transitIn', _vm.config.locale)) + " " + _vm._s(_vm.getAirportName(segment.to)) + " - " + _vm._s(_vm.$t('ticket.flightsInfo.transitTime', _vm.config.locale)) + ": " + _vm._s(segment.transitTime) + " ")]), _c('div', {
        staticClass: "border-dot flex-grow-1"
      })]) : _vm._e()];
    })], 2);
  }), 0) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }
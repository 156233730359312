<template lang="">
  <div
    v-if="flights.length"
    class="d-flex flex-column gap-16px"
  >
    <div
      v-for="(flight, index) in flights"
      :key="index"
      class="section-flight rounded-8px d-flex flex-column position-relative border-theme border-2"
      :style="config.showBackgroundColor ? `background-color: ${currentBgColor} !important` : ''"
    >
      <div
        class="d-flex gap-4px position-absolute"
        style="top: 8px; right: 8px; z-index: 10;"
      >
        <b-button
          class="pax-button"
          variant="danger"
          style="padding: 4px; border: 0.5px solid white !important;"
          @click="removeFlight(index)"
        >
          <feather-icon
            icon="MinusIcon"
            size="16"
          />
        </b-button>
        <b-button
          class="pax-button"
          variant="success"
          style="padding: 4px; border: 0.5px solid white !important;"
          @click="addFlight(index, flight)"
        >
          <feather-icon
            icon="PlusIcon"
            size="16"
          />
        </b-button>
      </div>

      <div class="d-flex-center bg-theme-dark text-white">
        <div class="mx-1 my-50 bg-white py-25 px-50 rounded-8px">
          <IAmLogoAirline
            :airline="flight.segments[0].operating === 'BL' ? flight.segments[0].operating : flight.segments[0].airline"
            :long-logo="true"
            :hide-tooltip="true"
            :height="48"
            blob
          />
        </div>
        <div class="flex-1 d-flex-center fw-700 text-24px">
          {{ resolveTitleFlight(flight) }}
        </div>
      </div>

      <template v-for="(segment, sIndex) in flight.segments">
        <div
          :key="`segment-wrapper-${index}-${sIndex}`"
          class="segment-content"
        >
          <div
            v-if="agencyAvatar"
            v-show="config.showBackground"
            :style="`position: absolute; inset: 30px; opacity: 0.08; background: url(${agencyAvatar}) no-repeat center / contain`"
          />
          <div
            class="segment-content-l flex-1 d-flex gap-16px text-black"
            style="padding-right: 0"
          >
            <!-- ANCHOR from -->
            <div class="flex-1 d-flex">
              <div class="d-flex flex-column gap-12px fw-800 text-22px w-100">
                <div>
                  <div
                    ref="fromRef"
                    contenteditable="true"
                    @focus="onFocus"
                    @blur="onBlur"
                  >
                    {{ getAirportName(segment.from) }}
                  </div>
                  <div class="border-bottom-dashed-theme" />
                </div>

                <div class="d-flex gap-4px align-items-center">
                  <span contenteditable="true">
                    {{ $t('ticket.flightsInfo.departTime', config.locale) }}:
                  </span>
                  <span
                    class="text-24px color-theme rounded-8px"
                    style="padding-top: 2px;"
                    contenteditable="true"
                  >
                    {{ segment.departTime }}
                  </span>
                </div>
                <div class="text-20px">
                  <span
                    contenteditable="true"
                    class="text-uppercase"
                  >
                    {{
                      convertISODateTime(
                        segment?.segmentNotModified?.departure?.at, segment?.segmentNotModified?.departure?.timeZone, config.locale
                      ).dayOfWeek
                        || segment.departWeekday
                    }}, {{ segment.departDate }}
                  </span>
                </div>
                <div
                  v-if="segment.startTerminal"
                  class="d-flex gap-4px align-items-center"
                >
                  <IAmIcon
                    icon="location"
                    size="18"
                  />
                  <span contenteditable="true">
                    {{ $t('ticket.flightsInfo.terminal', config.locale) }} {{ segment.startTerminal.replace('TERMINAL', '') }}
                  </span>
                </div>
                <!-- ANCHOR booking class -->
                <div
                  v-if="config.showBookingClass"
                  :key="bookingClassKey"
                  class="d-flex-center cursor-pointer position-relative"
                  @click="onClickBookingClass(segment)"
                >
                  <img
                    v-if="segment?.customBookingClass || getGroupClass(segment)"
                    v-b-tooltip.hover.top.window="$t('ticket.clickChangeBookingClassBadge')"
                    :src="require(`@/assets/images/ticket/img_ticket_${(segment?.customBookingClass || getGroupClass(segment)).toLowerCase()}_class.png`)"
                    class="rounded-8px w-100"
                    style="max-width: 320px;"
                  >
                  <img
                    v-else
                    v-b-tooltip.hover.v-danger.top.window="$t('ticket.errorBookingClassBadge')"
                    :src="require(`@/assets/images/ticket/img_ticket_${'economy'}_class.png`)"
                    class="rounded-8px w-100 border-danger"
                    style="max-width: 320px; border-width: 2px !important;"
                  >
                </div>
              </div>
              <div class="divide-line ml-1" />
            </div>

            <!-- ANCHOR to -->
            <div class="flex-1 d-flex">
              <div class="d-flex flex-column gap-12px fw-600 text-22px w-100">
                <div>
                  <div
                    ref="toRef"
                    contenteditable="true"
                    @focus="onFocus"
                    @blur="onBlur"
                  >
                    {{ getAirportName(segment.to) }}
                  </div>
                  <div class="border-bottom-dashed-theme" />
                </div>
                <div class="d-flex gap-4px align-items-center">
                  <span contenteditable="true">
                    {{ $t('ticket.flightsInfo.arrivalTime', config.locale) }}:
                  </span>
                  <span
                    class="text-24px color-theme rounded-8px"
                    style="padding-top: 2px;"
                    contenteditable="true"
                  >
                    {{ segment.arrivalTime }}
                  </span>
                </div>
                <div class="text-20px">
                  <span
                    contenteditable="true"
                    class="text-uppercase"
                  >
                    {{
                      convertISODateTime(
                        segment?.segmentNotModified?.arrival?.at, segment?.segmentNotModified?.arrival?.timeZone, config.locale
                      ).dayOfWeek
                        || segment.arrivalWeekday
                    }}, {{ segment.arrivalDate }}
                  </span>
                </div>
                <div
                  v-if="segment.endTerminal"
                  class="d-flex gap-4px align-items-center"
                >
                  <IAmIcon
                    icon="location"
                    size="18"
                  />
                  <span contenteditable="true">
                    {{ $t('ticket.flightsInfo.terminal', config.locale) }} {{ segment.endTerminal.replace('TERMINAL', '') }}
                  </span>
                </div>
              </div>
              <div class="divide-line ml-1" />
            </div>
          </div>

          <div
            class="segment-content-r d-flex flex-column gap-8px fw-600 text-20px"
            style="max-width: unset"
          >
            <div>
              <div class="airline d-flex-between gap-4px">
                <div class="text-nowrap">
                  {{ $t('ticket.flightsInfo.airline', config.locale) }}
                </div>
                <div v-if="airlinesLogoLong.includes(segment.operating || segment.airline)">
                  <IAmLogoAirline
                    :airline="segment.operating === 'BL' ? segment.operating : segment.airline"
                    :long-logo="true"
                    :hide-tooltip="true"
                    height="60"
                    blob
                  />
                </div>
                <div
                  v-else
                  class="d-flex-center gap-1"
                >
                  <IAmLogoAirline
                    :airline="segment.operating === 'BL' ? segment.operating : segment.airline"
                    :hide-tooltip="true"
                    :height="50"
                    blob
                  />
                  <div class="text-nowrap">
                    {{ getAirlineNameByCode(segment.operating === 'BL' ? segment.operating : segment.airline) }}
                  </div>
                </div>
              </div>
              <div class="border-bottom-dashed-theme" />
            </div>

            <!-- flight -->
            <div class="d-flex align-items-center gap-4px">
              <div contenteditable="true">
                {{ $t('ticket.flightsInfo.flight', config.locale) }}:
              </div>
              <div
                class="color-theme px-50"
                contenteditable="true"
              >
                {{ segment.flight }}
              </div>
            </div>

            <!-- aircraft -->
            <div
              v-if="segment.airCraft"
              class="d-flex align-items-center gap-4px"
            >
              <div contenteditable="true">
                {{ $t('ticket.flightsInfo.aircraft', config.locale) }}:
              </div>
              <div
                class="px-50"
                contenteditable="true"
              >
                {{ getAirCraftByIata(segment.airCraft) }}
              </div>
            </div>

            <!-- duration -->
            <div class="d-flex align-items-center gap-4px">
              <div contenteditable="true">
                {{ $t('ticket.flightsInfo.duration', config.locale) }}:
              </div>
              <div
                class="px-50"
                contenteditable="true"
              >
                {{ segment.durationHM }}
              </div>
            </div>

            <!-- boarding baggage -->
            <div
              v-if="segment.segmentNotModified
                ? getBaggageInBookingDetail(segment.segmentNotModified, ticketData, config.locale).carryBag
                : getHandBaggage(segment)"
              class="d-flex align-items-center gap-4px"
            >
              <div contenteditable="true">
                {{ $t('ticket.flightsInfo.handBaggage', config.locale) }}:
              </div>
              <div
                class="px-50"
                contenteditable="true"
              >
                <!-- TODO: kiện -->
                {{ segment.segmentNotModified
                  ? getBaggageInBookingDetail(segment.segmentNotModified, ticketData, config.locale).carryBag
                  : getHandBaggage(segment) }}
              </div>
            </div>

            <!-- checkin baggage -->
            <div
              v-if="segment.segmentNotModified
                ? getBaggageInBookingDetail(segment.segmentNotModified, ticketData, config.locale).checkinBag
                : getFreeBaggage(segment)"
              class="d-flex align-items-center gap-4px"
            >
              <div contenteditable="true">
                {{ $t('ticket.flightsInfo.freeBaggage', config.locale) }}:
              </div>
              <div
                class="px-50"
                contenteditable="true"
              >
                <!-- TODO: kiện -->
                {{ segment.segmentNotModified
                  ? getBaggageInBookingDetail(segment.segmentNotModified, ticketData, config.locale).checkinBag
                  : getFreeBaggage(segment) }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="segment.transitTime"
          :key="`transit-time${index}-${sIndex}`"
          class="d-flex-center gap-12px px-2 color-theme my-12px"
        >
          <div class="border-dot flex-grow-1" />
          <div
            class="flex-shrink-0 font-weight-bolder text-14px"
            contenteditable
          >
            {{ $t('ticket.flightsInfo.transitIn', config.locale) }}
            {{ getAirportName(segment.to) }}
            -
            {{ $t('ticket.flightsInfo.transitTime', config.locale) }}:
            {{ segment.transitTime }}
          </div>
          <div class="border-dot flex-grow-1" />
        </div>
      </template>
    </div>
  </div>
</template>
<script>

import { BButton, VBTooltip } from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'
import { v4 as uuidv4 } from 'uuid'
import max from 'lodash/max'

import { airlinesLogoLong } from '@/constants/reservation'
import store from '@/store'
import { convertISODateTime } from '@/@core/utils/filter'
import { upperFirstPerWord } from '@/@core/utils/utils'

import { resolveAirPnr } from '@reservation/useReservationHandle'

export default {
  components: {
    BButton,
    IAmLogoAirline: () => import('@/components/IAmLogoAirline.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    ticketData: {
      type: Object,
      default: () => ({}),
    },
    flights: {
      type: Array,
      default: () => [],
    },
    agencyAvatar: {
      type: [String, null],
      default: null,
    },
    currentBgColor: {
      type: [String, null],
      default: null,
    },
    getAirportName: {
      type: Function,
      default: () => {},
    },
    getGroupClass: {
      type: Function,
      default: () => {},
    },
    getFreeBaggage: {
      type: Function,
      default: () => {},
    },
    getHandBaggage: {
      type: Function,
      default: () => {},
    },
    getBaggageInBookingDetail: {
      type: Function,
      default: () => {},
    },
    addFlight: {
      type: Function,
      default: () => {},
    },
    removeFlight: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    function getAirCraftByIata(code) {
      return store.getters['globalConfig/getAirCraftByIata'](code)
    }

    function getAirlineNameByCode(code) {
      return store.getters['globalConfig/getAirlineNameByCode'](code)
    }

    const bookingClassKey = ref(uuidv4())
    const bookingClassBadge = ['ECONOMY', 'BUSINESS', 'PREMIUM', 'FIRST']
    function onClickBookingClass(segment) {
      const bookingClassOfSegment = segment?.customBookingClass ?? this.getGroupClass(segment) ?? 'ECONOMY'
      const indexBookingClassInArray = bookingClassBadge.indexOf(bookingClassOfSegment.toUpperCase())
      const nextBookingClass = bookingClassBadge[(indexBookingClassInArray >= 3 ? -1 : indexBookingClassInArray) + 1]
      segment.customBookingClass = nextBookingClass
      bookingClassKey.value = uuidv4()
    }

    function toggleConfigStatus(flight) {
      const statusOptions = ['PAID', 'HOLD', 'CANCEL']
      const indexBookingStatus = statusOptions.findIndex(i => i === flight.bookingStatus || i === props.config.status)
      flight.bookingStatus = indexBookingStatus + 1 === 3 ? statusOptions[0] : statusOptions[indexBookingStatus + 1]
      // eslint-disable-next-line vue/no-mutating-props
      props.config.status = indexBookingStatus + 1 === 3 ? statusOptions[0] : statusOptions[indexBookingStatus + 1]
    }

    function resolveTitleFlight(flight) {
      const departureDate = flight.segments[0].departDate
      const dayOfWeek = convertISODateTime(departureDate.split('/').reverse().join('-'), undefined, props.config.locale).dayOfWeekFullText
      const trip = flight.trip.split('-').map(airport => props.getAirportName(airport, false)).join(' - ')
      return `(${upperFirstPerWord(dayOfWeek)}) ${departureDate} ${trip}`
    }
    const fromRef = ref()
    const toRef = ref()

    function setClientHeight() {
      for (let i = 0; i < fromRef.value.length; i += 1) {
        const maxClientHeight = max([fromRef.value[i].clientHeight, toRef.value[i].clientHeight])
        fromRef.value[i].style.height = `${maxClientHeight}px`
        toRef.value[i].style.height = `${maxClientHeight}px`
      }
    }
    watch([fromRef, toRef], () => {
      if (fromRef.value && toRef.value) {
        setClientHeight()
      }
    }, { immediate: true, deep: true })
    function onFocus(event) {
      event.target.style.height = 'auto'
    }
    function onBlur() {
      setClientHeight()
    }
    return {
      resolveAirPnr,
      airlinesLogoLong,
      getAirCraftByIata,
      onClickBookingClass,
      bookingClassKey,
      toggleConfigStatus,
      getAirlineNameByCode,
      resolveTitleFlight,
      fromRef,
      toRef,
      onFocus,
      onBlur,
      convertISODateTime,
    }
  },
}
</script>
<style lang="scss">

</style>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column gap-16px"
  }, [_c('BAlert', {
    staticClass: "px-2 py-1 mb-0",
    attrs: {
      "show": _vm.flights && !_vm.flights.length,
      "variant": "warning"
    }
  }, [_vm._v(" Không có hành trình trong mã đặt chỗ. ")]), _vm._l(_vm.flights, function (flight, index) {
    return _c('div', {
      key: index,
      staticClass: "section-flight rounded-12px d-flex flex-column text-gray bg-theme position-relative",
      class: [{
        'border-theme': !_vm.config.showBackgroundColor
      }],
      style: _vm.config.showBackgroundColor ? "background-color: ".concat(_vm.currentBgColor, " !important") : ''
    }, [_c('div', {
      staticClass: "d-flex gap-4px position-absolute",
      staticStyle: {
        "top": "8px",
        "right": "8px",
        "z-index": "10"
      }
    }, [_c('b-button', {
      staticClass: "pax-button",
      staticStyle: {
        "padding": "4px"
      },
      attrs: {
        "variant": "outline-danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removeFlight(index);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "MinusIcon",
        "size": "16"
      }
    })], 1), _c('b-button', {
      staticClass: "pax-button",
      staticStyle: {
        "padding": "4px"
      },
      attrs: {
        "variant": "outline-success"
      },
      on: {
        "click": function click($event) {
          return _vm.addFlight(index, flight);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "PlusIcon",
        "size": "16"
      }
    })], 1)], 1), _vm._l(flight.segments, function (segment, sIndex) {
      return [_c('div', {
        key: "segment-wrapper-".concat(index, "-").concat(sIndex),
        staticClass: "segment-content"
      }, [_vm.agencyAvatar ? _c('div', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: _vm.config.showBackground,
          expression: "config.showBackground"
        }],
        style: "position: absolute; inset: 30px; opacity: 0.08; background: url(".concat(_vm.agencyAvatar, ") no-repeat center / contain")
      }) : _vm._e(), _c('div', {
        staticClass: "segment-content-l d-flex flex-column gap-16px text-black"
      }, [_c('div', {
        staticClass: "segment-booking-code border-theme p-0 d-flex-center align-self-stretch color-theme bg-white position-relative"
      }, [_c('svg', {
        staticClass: "d-flex-center",
        attrs: {
          "viewBox": "0 0 67 67",
          "width": "80",
          "fill": "none",
          "xmlns": "http://www.w3.org/2000/svg"
        }
      }, [_c('path', {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          "d": "M-2.18175 31.151L-35 -13.198L-19.727 -24.5L21.4547 31.151L-19.727 86.802L-35 75.5L-2.18175 31.151Z",
          "fill": "currentColor"
        }
      }), _c('path', {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          "d": "M20.2728 31.151L-12.5454 -13.198L2.72759 -24.5L43.9093 31.151L2.72759 86.802L-12.5454 75.5L20.2728 31.151Z",
          "fill": "currentColor",
          "fill-opacity": "0.6869"
        }
      }), _c('path', {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          "d": "M42.7279 31.151L9.90967 -13.198L25.1827 -24.5L66.3644 31.151L25.1827 86.802L9.90967 75.5L42.7279 31.151Z",
          "fill": "currentColor",
          "fill-opacity": "0.2"
        }
      })]), _c('div', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: _vm.config.showStatus,
          expression: "config.showStatus"
        }],
        staticClass: "payment-status position-absolute cursor-pointer",
        class: flight.bookingStatus ? flight.bookingStatus.includes('PAID') ? 'bg-current' : flight.bookingStatus === 'HOLD' ? 'border-theme' : 'bg-danger border-danger' : _vm.config.status.toUpperCase().includes('PAID') ? 'bg-current' : 'border-theme',
        staticStyle: {
          "top": "24px"
        },
        attrs: {
          "id": "electronic-ticket-status"
        },
        on: {
          "click": function click($event) {
            return _vm.toggleConfigStatus(flight);
          }
        }
      }, [_c('span', {
        class: flight.bookingStatus ? flight.bookingStatus.includes('PAID') ? 'text-white' : flight.bookingStatus === 'HOLD' ? 'color-theme' : ' text-white' : _vm.config.status.toUpperCase().includes('PAID') ? 'text-white' : _vm.config.status === 'EXPIRED' ? 'text-red' : 'color-theme'
      }, [_vm._v(" " + _vm._s(_vm.$t("reservation.".concat(flight.bookingStatus || _vm.config.status), _vm.config.locale)) + " ")])]), _c('b-tooltip', {
        staticClass: "cursor-pointer",
        attrs: {
          "target": "electronic-ticket-status",
          "triggers": "hover",
          "placement": "top",
          "boundary": "viewport",
          "variant": "danger"
        }
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.clickToChangeStatus')) + " "), _c('span', {
        staticClass: "fw-700"
      }, [_vm._v(" " + _vm._s(_vm.config.status.toUpperCase().includes('PAID') ? _vm.$t('ticket.statusHold', _vm.config.locale) : _vm.config.status.toUpperCase() === 'HOLD' ? _vm.$t('ticket.statusCancel', _vm.config.locale) : _vm.$t('ticket.statusPaid', _vm.config.locale)) + " ")])]), _c('div', {
        staticClass: "fw-700 text-center text-24px flex-grow-0 px-1",
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.bookingID', _vm.config.locale)) + ": ")]), _c('div', {
        staticClass: "fw-700 text-center text-30px text-uppercase text-nowrap flex-grow-1",
        staticStyle: {
          "padding-right": "36px"
        },
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(flight.bookingCode) + " "), _vm.resolveAirPnr(flight.supplierPNRs, flight.airline) ? _c('span', [_vm._v("/" + _vm._s(_vm.resolveAirPnr(flight.supplierPNRs, flight.airline)))]) : _vm._e()])], 1), _c('div', {
        staticClass: "d-flex justify-content-between align-items-center fw-700 text-20px"
      }, [_c('div', {
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.getAirportName(segment.from)) + " ")]), _c('div', {
        staticClass: "text-right",
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.getAirportName(segment.to)) + " ")])]), _c('div', {
        staticClass: "d-flex justify-content-between align-items-end fw-700"
      }, [_c('div', [_c('div', {
        staticClass: "d-flex gap-4px align-items-center"
      }, [_c('span', {
        staticClass: "text-16px",
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.departTime', _vm.config.locale)) + " ")]), _c('span', {
        staticClass: "text-30px px-12px color-theme bg-white rounded-8px border-theme",
        staticStyle: {
          "padding-top": "2px"
        },
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(segment.departTime) + " ")])]), _c('div', {
        staticClass: "text-20px mt-4px"
      }, [_c('span', {
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(segment.departWeekday) + ", " + _vm._s(segment.departDate) + " ")])])]), _c('IAmIcon', {
        attrs: {
          "icon": "airplane",
          "size": "34",
          "color": "#747783"
        }
      }), _c('div', {
        staticClass: "text-right"
      }, [_c('div', {
        staticClass: "d-flex gap-4px align-items-center"
      }, [_c('span', {
        staticClass: "text-30px px-12px color-theme bg-white rounded-8px border-theme",
        staticStyle: {
          "padding-top": "2px"
        },
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(segment.arrivalTime) + " ")]), _c('span', {
        staticClass: "text-16px",
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.arrivalTime', _vm.config.locale)) + " ")])]), _c('div', {
        staticClass: "text-20px mt-4px"
      }, [_c('span', {
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(segment.arrivalWeekday) + ", " + _vm._s(segment.arrivalDate) + " ")])])])], 1), segment.startTerminal && segment.endTerminal ? _c('div', {
        staticClass: "d-flex justify-content-between align-items-center"
      }, [_c('div', {
        staticClass: "d-flex gap-8px align-items-center font-weight-bolder text-black"
      }, [_c('span', {
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.terminal', _vm.config.locale)) + " " + _vm._s(segment.startTerminal.replace('TERMINAL', '')) + " ")]), _c('IAmIcon', {
        attrs: {
          "icon": "dotOutline",
          "size": "18"
        }
      })], 1), _c('div', {
        staticClass: "d-flex gap-8px align-items-center font-weight-bolder"
      }, [_c('IAmIcon', {
        attrs: {
          "icon": "location",
          "size": "18"
        }
      }), _c('span', {
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.terminal', _vm.config.locale)) + " " + _vm._s(segment.endTerminal.replace('TERMINAL', '')) + " ")])], 1)]) : _vm._e()]), _vm._m(0, true), _c('div', {
        staticClass: "segment-content-r d-flex flex-column gap-8px fw-700"
      }, [_c('div', {
        staticClass: "airline d-flex-center gap-16px"
      }, [_vm.airlinesLogoLong.includes(segment.operating || segment.airline) ? _c('div', [_c('IAmLogoAirline', {
        attrs: {
          "airline": segment.operating === 'BL' ? segment.operating : segment.airline,
          "long-logo": true,
          "hide-tooltip": true,
          "size": "lg",
          "blob": ""
        }
      })], 1) : _c('div', {
        staticClass: "d-flex-center gap-1"
      }, [_c('IAmLogoAirline', {
        attrs: {
          "airline": segment.operating === 'BL' ? segment.operating : segment.airline,
          "hide-tooltip": true,
          "height": 50,
          "blob": ""
        }
      }), _c('div', {
        staticClass: "text-24px text-black font-weight-bolder"
      }, [_vm._v(" " + _vm._s(_vm.getAirlineNameByCode(segment.operating === 'BL' ? segment.operating : segment.airline)) + " ")])], 1)]), _vm.config.showBookingClass ? _c('div', {
        key: _vm.bookingClassKey,
        staticClass: "d-flex-center cursor-pointer",
        staticStyle: {
          "position": "relative"
        },
        on: {
          "click": function click($event) {
            return _vm.onClickBookingClass(segment);
          }
        }
      }, [segment !== null && segment !== void 0 && segment.customBookingClass || _vm.getGroupClass(segment) ? _c('img', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover.top.window",
          value: _vm.$t('ticket.clickChangeBookingClassBadge'),
          expression: "$t('ticket.clickChangeBookingClassBadge')",
          modifiers: {
            "hover": true,
            "top": true,
            "window": true
          }
        }],
        staticClass: "rounded-8px w-100",
        staticStyle: {
          "max-width": "320px"
        },
        attrs: {
          "src": require("@/assets/images/ticket/img_ticket_".concat(((segment === null || segment === void 0 ? void 0 : segment.customBookingClass) || _vm.getGroupClass(segment)).toLowerCase(), "_class.png"))
        }
      }) : _c('img', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover.v-danger.top.window",
          value: _vm.$t('ticket.errorBookingClassBadge'),
          expression: "$t('ticket.errorBookingClassBadge')",
          modifiers: {
            "hover": true,
            "v-danger": true,
            "top": true,
            "window": true
          }
        }],
        staticClass: "rounded-8px w-100 border-danger",
        staticStyle: {
          "max-width": "320px",
          "border-width": "2px !important"
        },
        attrs: {
          "src": require("@/assets/images/ticket/img_ticket_".concat('economy', "_class.png"))
        }
      })]) : _vm._e(), _c('div', {
        staticClass: "d-flex justify-content-between align-items-center gap-4px"
      }, [_c('div', {
        staticClass: "text-14px",
        staticStyle: {
          "margin-top": "10px"
        },
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.flight', _vm.config.locale)) + ": ")]), _c('div', {
        staticClass: "text-24px px-12px color-theme bg-white rounded-8px border-theme",
        staticStyle: {
          "padding-top": "2px"
        },
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(segment.flight) + " ")])]), segment.airCraft ? _c('div', {
        staticClass: "d-flex justify-content-between align-items-center gap-4px"
      }, [_c('div', {
        staticClass: "text-14px",
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.aircraft', _vm.config.locale)) + ": ")]), _c('div', {
        staticClass: "text-black",
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.getAirCraftByIata(segment.airCraft)) + " ")])]) : _vm._e(), _c('div', {
        staticClass: "d-flex justify-content-between align-items-center gap-4px"
      }, [_c('div', {
        staticClass: "text-14px",
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.duration', _vm.config.locale)) + ": ")]), _c('div', {
        staticClass: "text-black",
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(segment.durationHM) + " ")])]), (segment.segmentNotModified ? _vm.getBaggageInBookingDetail(segment.segmentNotModified, _vm.ticketData, _vm.config.locale).carryBag : _vm.getHandBaggage(segment)) ? _c('div', {
        staticClass: "d-flex justify-content-between align-items-center gap-4px"
      }, [_c('div', {
        staticClass: "text-14px",
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.handBaggage', _vm.config.locale)) + ": ")]), _c('div', {
        staticClass: "text-black",
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(segment.segmentNotModified ? _vm.getBaggageInBookingDetail(segment.segmentNotModified, _vm.ticketData, _vm.config.locale).carryBag : _vm.getHandBaggage(segment)) + " ")])]) : _vm._e(), (segment.segmentNotModified ? _vm.getBaggageInBookingDetail(segment.segmentNotModified, _vm.ticketData, _vm.config.locale).checkinBag : _vm.getFreeBaggage(segment)) ? _c('div', {
        staticClass: "d-flex justify-content-between align-items-center gap-4px"
      }, [_c('div', {
        staticClass: "text-14px",
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.freeBaggage', _vm.config.locale)) + ": ")]), _c('div', {
        staticClass: "text-black",
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(segment.segmentNotModified ? _vm.getBaggageInBookingDetail(segment.segmentNotModified, _vm.ticketData, _vm.config.locale).checkinBag : _vm.getFreeBaggage(segment)) + " ")])]) : _vm._e()])]), segment.transitTime ? _c('div', {
        key: "transit-time".concat(index, "-").concat(sIndex),
        staticClass: "d-flex-center gap-12px px-2 color-theme my-12px"
      }, [_c('div', {
        staticClass: "border-dot flex-grow-1"
      }), _c('div', {
        staticClass: "flex-shrink-0 font-weight-bolder text-14px",
        attrs: {
          "contenteditable": ""
        }
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.transitIn', _vm.config.locale)) + " " + _vm._s(_vm.getAirportName(segment.to)) + " - " + _vm._s(_vm.$t('ticket.flightsInfo.transitTime', _vm.config.locale)) + ": " + _vm._s(segment.transitTime) + " ")]), _c('div', {
        staticClass: "border-dot flex-grow-1"
      })]) : _vm._e()];
    })], 2);
  })], 2);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "barcode-wrapper"
  }, [_c('img', {
    attrs: {
      "src": "/ticket/barcode26x240.png",
      "alt": "barcode"
    }
  })]);
}]

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "e-ticket d-flex flex-column bg-white mx-auto",
    class: [_vm.printSize, "theme-".concat(_vm.currentTheme), {
      'border border-theme': _vm.frontendI18n
    }, {
      'border-theme': _vm.eticketTemplate === 'basic'
    }, {
      'showBackgroundColor': _vm.config.showBackgroundColor
    }]
  }, [_vm.eticketTemplate === 'modern' ? [_c('ETicketAgencyInfo', {
    attrs: {
      "config": _vm.config,
      "is-config": _vm.isConfig,
      "agencyAvatar": _vm.agencyAvatar,
      "editorOption": _vm.editorOption,
      "agencyInfo": _vm.agencyInfo,
      "handleResetAgencyInfo": _vm.handleResetAgencyInfo
    }
  }), _c('ETicketTitleAndTimeHold', {
    attrs: {
      "frontendI18n": _vm.frontendI18n,
      "config": _vm.config,
      "timeHold": _vm.timeHold
    }
  }), _c('ETicketPassengerInfo', {
    attrs: {
      "frontendI18n": _vm.frontendI18n,
      "config": _vm.config,
      "currentBgColor": _vm.currentBgColor,
      "qrBookingInfo": _vm.qrBookingInfo,
      "newPaxs": _vm.newPaxs,
      "removePax": _vm.removePax,
      "addPax": _vm.addPax
    }
  }), _c('ETicketItinerariesInfo', {
    attrs: {
      "flights": _vm.flights,
      "ticketData": _vm.ticketData,
      "config": _vm.config,
      "currentBgColor": _vm.currentBgColor,
      "agencyAvatar": _vm.agencyAvatar,
      "getAirportName": _vm.getAirportName,
      "getGroupClass": _vm.getGroupClass,
      "getFreeBaggage": _vm.getFreeBaggage,
      "getHandBaggage": _vm.getHandBaggage,
      "getBaggageInBookingDetail": _vm.getBaggageInBookingDetail,
      "addFlight": _vm.addFlight,
      "removeFlight": _vm.removeFlight
    }
  }), _c('ETicketPriceInfo', {
    attrs: {
      "config": _vm.config,
      "ticketData": _vm.ticketData,
      "currentBgColor": _vm.currentBgColor,
      "fareBookingDataArray": _vm.fareBookingDataArray,
      "sumAll": _vm.sumAll,
      "totalAncillaryPriceRaw": _vm.totalAncillaryPriceRaw,
      "ancillaryTotal": _vm.ancillaryTotal,
      "showTotalPerTrip": _vm.showTotalPerTrip,
      "totalAncillaryPrice": _vm.totalAncillaryPrice,
      "handleChangePax": _vm.handleChangePax,
      "handleChangeAncillaryPrice": _vm.handleChangeAncillaryPrice,
      "handleChangeSumAll": _vm.handleChangeSumAll,
      "handleChangeAncillaryItem": _vm.handleChangeAncillaryItem,
      "handleChangeBookingTotal": _vm.handleChangeBookingTotal,
      "handleChangeAncillarySum": _vm.handleChangeAncillarySum,
      "ancillariesBookingDataArray": _vm.ancillariesBookingDataArray
    }
  }), _c('ETicketNote', {
    attrs: {
      "config": _vm.config,
      "editorOption": _vm.editorOption,
      "isConfig": _vm.isConfig,
      "qrCodeAvatar": _vm.qrCodeAvatar,
      "defaultBankAccount": _vm.defaultBankAccount,
      "resolveBankCode": _vm.resolveBankCode,
      "bankAvatar": _vm.bankAvatar,
      "handleResetNote": _vm.handleResetNote
    }
  })] : _vm.eticketTemplate === 'basic' ? [_c('ETicketAgencyInfo', {
    staticClass: "rounded-lg",
    style: _vm.eticketTemplate === 'basic' ? 'background-color: #f6f6f6' : '',
    attrs: {
      "config": _vm.config,
      "is-config": _vm.isConfig,
      "agencyAvatar": _vm.agencyAvatar,
      "editorOption": _vm.editorOption,
      "agencyInfo": _vm.agencyInfo,
      "handleResetAgencyInfo": _vm.handleResetAgencyInfo
    }
  }), _c('ETicketTitleAndTimeHold', {
    attrs: {
      "frontendI18n": _vm.frontendI18n,
      "config": _vm.config,
      "timeHold": _vm.timeHold
    }
  }), _c('ETicketBookingCode', {
    attrs: {
      "config": _vm.config,
      "flights": _vm.flights,
      "ticketData": _vm.ticketData,
      "currentBgColor": _vm.currentBgColor,
      "addFlight": _vm.addFlight,
      "removeFlight": _vm.removeFlight
    }
  }), _c('ETicketPassengerInfo', {
    attrs: {
      "frontendI18n": _vm.frontendI18n,
      "config": _vm.config,
      "currentBgColor": _vm.currentBgColor,
      "qrBookingInfo": _vm.qrBookingInfo,
      "newPaxs": _vm.newPaxs,
      "removePax": _vm.removePax,
      "addPax": _vm.addPax,
      "eticketTemplate": _vm.eticketTemplate
    }
  }), _c('ETicketBasicItinerariesInfo', {
    attrs: {
      "flights": _vm.flights,
      "ticketData": _vm.ticketData,
      "config": _vm.config,
      "currentBgColor": _vm.currentBgColor,
      "agencyAvatar": _vm.agencyAvatar,
      "getAirportName": _vm.getAirportName,
      "getGroupClass": _vm.getGroupClass,
      "getFreeBaggage": _vm.getFreeBaggage,
      "getHandBaggage": _vm.getHandBaggage,
      "getBaggageInBookingDetail": _vm.getBaggageInBookingDetail,
      "addFlight": _vm.addFlight,
      "removeFlight": _vm.removeFlight
    }
  }), _c('ETicketPriceInfo', {
    attrs: {
      "config": _vm.config,
      "ticketData": _vm.ticketData,
      "currentBgColor": _vm.currentBgColor,
      "fareBookingDataArray": _vm.fareBookingDataArray,
      "sumAll": _vm.sumAll,
      "totalAncillaryPriceRaw": _vm.totalAncillaryPriceRaw,
      "ancillaryTotal": _vm.ancillaryTotal,
      "showTotalPerTrip": _vm.showTotalPerTrip,
      "totalAncillaryPrice": _vm.totalAncillaryPrice,
      "handleChangePax": _vm.handleChangePax,
      "handleChangeAncillaryPrice": _vm.handleChangeAncillaryPrice,
      "handleChangeSumAll": _vm.handleChangeSumAll,
      "handleChangeAncillaryItem": _vm.handleChangeAncillaryItem,
      "handleChangeBookingTotal": _vm.handleChangeBookingTotal,
      "handleChangeAncillarySum": _vm.handleChangeAncillarySum,
      "eticketTemplate": _vm.eticketTemplate,
      "ancillariesBookingDataArray": _vm.ancillariesBookingDataArray
    }
  }), _c('ETicketNote', {
    attrs: {
      "eticketTemplate": _vm.eticketTemplate,
      "config": _vm.config,
      "editorOption": _vm.editorOption,
      "isConfig": _vm.isConfig,
      "qrCodeAvatar": _vm.qrCodeAvatar,
      "defaultBankAccount": _vm.defaultBankAccount,
      "resolveBankCode": _vm.resolveBankCode,
      "bankAvatar": _vm.bankAvatar,
      "handleResetNote": _vm.handleResetNote
    }
  })] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ticketData.status !== 'CANCEL' ? _c('div', [_vm.config.showPrice && _vm.fareBookingDataArray.length ? _c('div', {
    staticClass: "d-flex flex-column",
    class: "".concat(_vm.isBasicType ? 'border-theme border-2 rounded-lg' : 'gap-8px')
  }, [_vm.config.showPriceDetail ? _c('div', {
    staticClass: "d-flex-center flex-column gap-8px"
  }, [!_vm.isBasicType ? _c('div', {
    staticClass: "font-weight-bolder color-theme px-24px d-flex justify-content-start w-100 text-18px",
    attrs: {
      "contenteditable": "true"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.ticketPrice', _vm.config.locale)) + " ")]) : _vm._e(), _vm._l(_vm.fareBookingDataArray, function (paxFareItem, paxFareIndex) {
    return _c('div', {
      key: paxFareIndex,
      staticClass: "w-100"
    }, [paxFareItem && paxFareItem.trip ? _c('div', {
      staticClass: "text-dark font-weight-bolder d-flex-between text-18px",
      class: "".concat(_vm.isBasicType ? 'bg-theme-dark px-1 py-50' : 'mb-50')
    }, [_vm.isBasicType ? _c('div', {
      class: "font-weight-bolder ".concat(!_vm.isBasicType ? 'color-theme' : 'text-white'),
      attrs: {
        "contenteditable": "true"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('ticket.ticketPrice', _vm.config.locale)) + " ")]) : _vm._e(), _c('span', {
      class: "".concat(_vm.isBasicType ? 'text-white' : ''),
      attrs: {
        "contenteditable": ""
      }
    }, [_vm._v(" " + _vm._s(paxFareItem.trip) + " ")])]) : _vm._e(), _c('div', {
      staticClass: "section-table",
      class: [{
        'border-theme': !_vm.config.showBackgroundColor
      }, {
        'border-0': _vm.isBasicType
      }]
    }, [paxFareItem && paxFareItem.paxFare && paxFareItem.paxFare.length ? _c('b-table', {
      class: "mb-0 ".concat(_vm.isBasicType ? 'table-border-theme' : ''),
      attrs: {
        "centered": "",
        "responsive": "",
        "borderless": !_vm.isBasicType || true,
        "thead-class": "text-dark align-middle text-nowrap text-right border-0",
        "tbody-class": "text-dark align-middle text-nowrap text-right border-0",
        "fields": _vm.tableColumnsPaxFareInfoTicket,
        "items": paxFareItem.paxFare
      },
      scopedSlots: _vm._u([_vm._l(_vm.tableColumnsPaxFareInfoTicket, function (column, indexColumn) {
        return {
          key: "head(".concat(column.key, ")"),
          fn: function fn() {
            return [_c('span', {
              key: indexColumn,
              staticClass: "fw-600 text-14px",
              attrs: {
                "contenteditable": ""
              }
            }, [_vm._v(" " + _vm._s(_vm.$t("reservation.sellingPriceDetails.tableColumnsPaxFareInfoTicket.".concat(column.key), _vm.config.locale)) + " ")])];
          },
          proxy: true
        };
      }), {
        key: "cell(passenger)",
        fn: function fn(data) {
          return [_c('div', {
            staticClass: "text-right font-weight-bolder"
          }, [_c('span', {
            attrs: {
              "contenteditable": ""
            }
          }, [_vm._v(_vm._s(_vm.$t("reservation.".concat(data.item.paxType), _vm.config.locale)) + " x" + _vm._s(data.item.amountPassenger))])])];
        }
      }, {
        key: "cell(netPrice)",
        fn: function fn(data) {
          return [_c('div', {
            staticClass: "text-right font-weight-bolder"
          }, [_c('IAmInputMoney', {
            attrs: {
              "value-money": Math.floor(data.item.netPrice / data.item.amountPassenger),
              "placeholder": "",
              "disable-class": true,
              "can-input-negative": true,
              "on-blur": function onBlur(text) {
                return _vm.handleChangePax(text, paxFareIndex, data.item.paxType, 'netPrice');
              }
            }
          })], 1)];
        }
      }, {
        key: "cell(tax)",
        fn: function fn(data) {
          return [_c('div', {
            staticClass: "text-right font-weight-bolder"
          }, [_c('IAmInputMoney', {
            attrs: {
              "value-money": Math.floor(data.item.tax / data.item.amountPassenger),
              "placeholder": "",
              "disable-class": true,
              "can-input-negative": true,
              "on-blur": function onBlur(text) {
                return _vm.handleChangePax(text, paxFareIndex, data.item.paxType, 'tax');
              }
            }
          })], 1)];
        }
      }, {
        key: "cell(total)",
        fn: function fn(data) {
          return [_c('div', {
            staticClass: "text-right font-weight-bolder color-theme"
          }, [_c('IAmInputMoney', {
            attrs: {
              "customClass": "fw-600",
              "value-money": Math.floor(data.item.total / data.item.amountPassenger),
              "placeholder": "",
              "disable-class": true,
              "can-input-negative": true,
              "on-blur": function onBlur(text) {
                return _vm.handleChangePax(text, paxFareIndex, data.item.paxType, 'total');
              }
            }
          })], 1)];
        }
      }], null, true)
    }) : paxFareItem && paxFareItem.fareInfoTicket ? _c('b-table', {
      class: "mb-0 ".concat(_vm.isBasicType ? 'table-border-theme' : ''),
      attrs: {
        "centered": "",
        "responsive": "",
        "borderless": !_vm.isBasicType || true,
        "thead-class": "text-dark align-middle text-nowrap text-right border-0",
        "tbody-class": "text-dark align-middle text-nowrap text-right border-0",
        "fields": _vm.tableColumnsFareInfoTicket,
        "items": paxFareItem.fareInfoTicket
      },
      scopedSlots: _vm._u([_vm._l(_vm.tableColumnsFareInfoTicket, function (column, indexColumn) {
        return {
          key: "head(".concat(column.key, ")"),
          fn: function fn() {
            return [_c('span', {
              key: indexColumn,
              staticClass: "fw-600 text-14px",
              attrs: {
                "contenteditable": ""
              }
            }, [_vm._v(" " + _vm._s(_vm.$t("reservation.sellingPriceDetails.tableColumnsPaxFareInfoTicket.".concat(column.key), _vm.config.locale)) + " ")])];
          },
          proxy: true
        };
      }), {
        key: "cell(passenger)",
        fn: function fn(data) {
          return [_c('div', {
            staticClass: "text-right font-weight-bolder"
          }, [_c('span', {
            attrs: {
              "contenteditable": ""
            }
          }, [_vm._v(_vm._s(data.item.passenger))])])];
        }
      }, {
        key: "cell(netPrice)",
        fn: function fn(data) {
          return [_c('div', {
            staticClass: "text-right font-weight-bolder"
          }, [_c('IAmInputMoney', {
            attrs: {
              "value-money": data.item.netPrice,
              "placeholder": "",
              "disable-class": true,
              "can-input-negative": true,
              "on-blur": function onBlur(text) {
                return _vm.handleChangePax(text, paxFareIndex, data.item.passenger, 'netPrice', true);
              }
            }
          })], 1)];
        }
      }, {
        key: "cell(tax)",
        fn: function fn(data) {
          return [_c('div', {
            staticClass: "text-right font-weight-bolder"
          }, [_c('IAmInputMoney', {
            attrs: {
              "value-money": data.item.tax,
              "placeholder": "",
              "disable-class": true,
              "can-input-negative": true,
              "on-blur": function onBlur(text) {
                return _vm.handleChangePax(text, paxFareIndex, data.item.passenger, 'tax', true);
              }
            }
          })], 1)];
        }
      }, {
        key: "cell(total)",
        fn: function fn(data) {
          return [_c('div', {
            staticClass: "text-right font-weight-bolder color-theme"
          }, [_c('IAmInputMoney', {
            attrs: {
              "value-money": data.item.total,
              "placeholder": "",
              "customClass": "fw-600",
              "disable-class": true,
              "can-input-negative": true,
              "on-blur": function onBlur(text) {
                return _vm.handleChangePax(text, paxFareIndex, data.item.passenger, 'total', true);
              }
            }
          })], 1)];
        }
      }], null, true)
    }) : _vm._e(), _vm.showTotalPerTrip ? _c('div', {
      staticClass: "text-right px-1 py-50",
      class: "".concat(_vm.isBasicType ? 'border-theme-top' : '')
    }, [_c('span', {
      attrs: {
        "contenteditable": ""
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('ticket.priceTable.total', _vm.config.locale)) + ": ")]), _c('IAmInputMoney', {
      attrs: {
        "value-money": paxFareItem.total,
        "placeholder": "",
        "custom-class": "fw-700 color-theme",
        "disable-class": true,
        "can-input-negative": true,
        "on-blur": function onBlur(text) {
          return _vm.handleChangeBookingTotal(text, paxFareIndex);
        }
      }
    })], 1) : _vm._e()], 1)]);
  })], 2) : _vm._e(), _vm.config.showAncillaries && _vm.config.showPriceDetail && _vm.ancillaryTotal ? _c('div', {
    class: "d-flex-center flex-column mt-1 ".concat(!_vm.isBasicType ? 'gap-8px' : '')
  }, [_c('div', {
    staticClass: "font-weight-bolder px-24px d-flex justify-content-start w-100 text-18px",
    class: _vm.isBasicType ? 'text-white bg-theme-dark px-1 py-50' : 'color-theme',
    attrs: {
      "contenteditable": "true"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.ticketAncillaries', _vm.config.locale)) + " ")]), _vm._l(_vm.ancillariesBookingDataArray, function (paxFareItem, paxFareIndex) {
    return _c('div', {
      key: paxFareIndex,
      staticClass: "w-100"
    }, [paxFareItem && paxFareItem.trip ? _c('div', {
      staticClass: "text-dark font-weight-bolder mb-50"
    }, [_c('span', {
      staticClass: "text-16px"
    }, [_c('span', {
      attrs: {
        "contenteditable": ""
      }
    }, [_vm._v(" " + _vm._s(paxFareItem.trip) + " ")])])]) : _vm._e(), _c('div', {
      staticClass: "section-table",
      class: [{
        'border-theme': !_vm.config.showBackgroundColor && !_vm.isBasicType
      }]
    }, [_c('AncillariesTable', {
      attrs: {
        "isBasicType": _vm.isBasicType,
        "reservations-data": paxFareItem,
        "on-change": function onChange(text, itemId) {
          return _vm.handleChangeAncillaryItem(text, paxFareIndex, itemId);
        },
        "config": _vm.config
      }
    }), _vm.showTotalPerTrip ? _c('div', {
      staticClass: "text-right m-50"
    }, [_c('span', {
      attrs: {
        "contenteditable": ""
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('ticket.priceTable.total', _vm.config.locale)) + ": ")]), _c('IAmInputMoney', {
      attrs: {
        "value-money": paxFareItem.ancillaryTotal,
        "placeholder": "",
        "custom-class": "fw-700 color-theme",
        "disable-class": true,
        "can-input-negative": true,
        "on-blur": function onBlur(text) {
          return _vm.handleChangeAncillarySum(text, paxFareIndex);
        }
      }
    })], 1) : _vm._e()], 1)]);
  })], 2) : _vm._e(), _c('div', {
    staticClass: "d-flex flex-column gap-2 px-1 py-75 w-100",
    class: _vm.isBasicType ? 'bg-theme-dark text-white border-theme-top' : 'rounded-8px bg-theme',
    style: "".concat(_vm.config.showBackgroundColor && !_vm.isBasicType ? "background-color: ".concat(_vm.currentBgColor, " !important") : '')
  }, [_vm.totalAncillaryPriceRaw && _vm.config.showPriceDetail && _vm.config.showAncillaries ? _c('div', {
    staticClass: "d-flex align-items-end justify-content-end"
  }, [_c('span', {
    staticClass: "font-weight-bolder",
    attrs: {
      "contenteditable": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.ticketAncillaries', _vm.config.locale)) + ": ")]), _c('IAmInputMoney', {
    attrs: {
      "value-money": _vm.totalAncillaryPrice,
      "placeholder": "",
      "custom-class": "text-18px fw-700 ".concat(_vm.isBasicType ? 'text-white' : 'color-theme'),
      "disable-class": true,
      "can-input-negative": true,
      "on-blur": function onBlur(text) {
        return _vm.handleChangeAncillaryPrice(text);
      }
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "d-flex align-items-end justify-content-end"
  }, [_c('span', {
    staticClass: "font-weight-bolder",
    attrs: {
      "contenteditable": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.priceTable.totalOfPrice', _vm.config.locale)) + ": ")]), _c('IAmInputMoney', {
    attrs: {
      "value-money": _vm.sumAll,
      "placeholder": "",
      "custom-class": "text-22px fw-700 ".concat(_vm.isBasicType ? 'text-white' : 'color-theme'),
      "disable-class": true,
      "can-input-negative": true,
      "on-blur": function onBlur(text) {
        return _vm.handleChangeSumAll(text);
      }
    }
  })], 1)])]) : _vm._e()]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }